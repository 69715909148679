import React, { useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex } from '../components/primitives'

export default ({ navigate }) => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      headTitle={'Privacy policy | Hemset.se'}
      blackNav={true}
     >
      <Flex jusifyContent={'center'}>
        <Flex
          mt={['80px', '80px', '80px']}
          maxWidth={'1000px'}
          minHeight={'100vh'}
          p={4}
          flexDirection={'column'}>
          <Flex flexDirection={'column'}>
            <h1>Policy avseende integritet och marknadsföring</h1>

            <h2>1. Allmänt</h2>
            <p>
              Denna policy avseende integritet och marknadsföring (”
              Integritetspolicy”) beskriver hur Hemset AB, org. nr 559089-0736,
              Nikolaigatan 4 A, 214 21, Malmö, e-mail: info@hemset.se, (”
              Hemset”, ” vi”) samlar in, använder, lämnar ut och lagrar dina
              personuppgifter.
            </p>
            <p>
              1.1 Integritetspolicyn gäller då Hemset tillhandahåller tjänster
              och produkter i anslutning till köp, serviceärenden och övrig
              kontakt med Hemset, såsom besök på webbplats.
            </p>
            <p>
              1.2 Du ska alltid kunna känna dig trygg när du lämnar dina
              personuppgifter till oss. Vi vill med denna Integritetspolicy visa
              hur vi säkerställer att dina personuppgifter behandlas i enlighet
              med gällande personuppgiftslagstiftning.
            </p>

            <h2>2. Personuppgiftsansvarig</h2>
            <p>
              Hemset är personuppgiftsansvarig för Hemsets behandling av dina
              personuppgifter och ansvarar för att sådan behandling sker i
              enlighet med tillämplig lagstiftning.
            </p>

            <h2>3. När behandlar vi dina personuppgifter?</h2>
            <p>
              3.1 För att du ska kunna besöka vår webbplats, köpa våra varor
              eller tjänster eller kontakta oss för service eller information
              måste vi samla in och behandla personuppgifter om dig.
            </p>
            <p>
              3.2 Hemset samlar in och behandlar personuppgifter om dig när du
              genomför en beställning på Hemsets webbplats, använder dig av
              Hemsets support, besöker Hemsets webbplats eller när du på annat
              sätt har kontakt med Hemset. Informationen som samlas in från dig
              vid bokningar och köp krävs för att du ska kunna ingå avtal med
              Hemset och för att Hemset ska kunna tillhandahålla sina tjänster
              och erbjudanden.
            </p>
            <p>
              3.3 Vi samlar även in och uppdaterar dina adressuppgifter via
              tredje parts tjänster för adressuppdatering.
            </p>
            <h2>4. Vilka personuppgifter behandlar vi om dig?</h2>
            <p>
              4.1 <b>För dig som är användare till Hemset</b>
              <br />
              De personuppgifter Hemset samlar in och behandlar om dig i
              egenskap av användare som genomför köp och använder våra
              support-tjänster är:
            </p>
            <ul>
              <li>
                Namn och identifikationsnummer (personnummer, nationellt id
                eller organisationsnummer)
              </li>
              <li>Adress</li>
              <li>Telefonnummer och e-post</li>
              <li>Betalningsuppgifter</li>
              <li>Kundnummer</li>
              <li>
                IP-adress och information om din användning av Hemsets webbplats
              </li>
            </ul>

            <h2>5. Varför behandlar vi uppgifter om dig?</h2>
            <p>
              <b>5.1 För dig som är användare till Hemset</b>
              <br />
              Hemset behandlar dina personuppgifter för olika ändamål.
              Huvudsakligen behandlar Hemset dina personuppgifter i syfte att:
            </p>
            <ul>
              <li>
                Fullgöra våra förpliktelser gentemot dig som användare, såsom
                genomförande av köp, fakturering och tillhandahållande av
                support.
              </li>
              <li>
                Möjliggöra allmän kundvård och kundservice, som för att besvara
                frågor och rätta felaktiga uppgifter.
              </li>
              <li>
                Lämna information och rikta marknadsföring, per post, e-post
                samt telefon avseende Hemsets och utvalda samarbetspartners
                varor och tjänster.
              </li>
              <li>
                Hantera kundförhållandet och tillhandahålla våra tjänster.
              </li>
              <li>
                Ge dig relevant information och anpassade erbjudanden i
                nyhetsbrev samt på webben.
              </li>
              <li>
                Bedöma vilka betalningsmetoder vi kan erbjuda dig, till exempel
                genom kreditbedömningar.
              </li>
              <li>
                Förbättra vårt kunderbjudande, till exempel utveckling av
                tjänster, produkter och funktioner.
              </li>
              <li>Förhindra bedrägerier och utföra riskhantering.</li>
              <li>
                Följa tillämplig lagstiftning, till exempel bokföringslagar.
              </li>
            </ul>
            <p>
              Uppgifterna kan även utgöra underlag för marknads- och
              kundanalyser, marknadsundersökningar, statistik, affärsuppföljning
              samt affärs- och metodutveckling relaterat till köp av varor och
              tjänster.
            </p>

            <h2>
              6. De lagliga grunderna för vår behandling av dina personuppgifter
            </h2>
            <p>
              6.1 Hemset baserar behandlingen av dina personuppgifter på ett
              antal lagliga grunder. Dessa beskrivs i det här avsnittet.
            </p>
            <p>
              6.2 Vi behandlar bland annat dina personuppgifter för att kunna
              fullgöra avtalet med dig som användare, såsom för att genomföra
              bokningar och köp.
            </p>
            <p>
              6.3 En del av den behandling av personuppgifter som vi utför
              baserar sig på en så kallad intresseavvägning. Detta gäller
              exempelvis den behandling vi utför för att kunna skicka
              erbjudanden till dig om våra varor och tjänster samt för att göra
              en begränsad segmentering av användare, t.ex. baserat på totala
              inköpssummor. Hemset behandlar inte känsliga personuppgifter med
              stöd av en intresseavvägning och utför ingen behandling som utgör
              profilering med stöd av en intresseavvägning.
            </p>
            <p>
              6.4 I vissa fall kan Hemset ha en rättslig förpliktelse att
              behandla dina personuppgifter. Detta gäller till exempel för den
              behandling av personuppgifter som vi utför för att kunna uppfylla
              bokföringslagens krav.
            </p>

            <h2>7. Sammanställning av vår personuppgiftsbehandling</h2>
            <table className='responsive-table'>
              <thead>
                <tr>
                  <th>Ändamål</th>
                  <th>Rättslig grund</th>
                  <th>Kategorier av personuppgifter</th>
                  <th>Lagringstid</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    För att administrera och leverera våra varor och tjänster
                    samt tillhandahålla support
                  </td>
                  <td>Fullföljande av avtal om köp (försäljningsvillkor)</td>
                  <td>
                    <ul>
                      <li>Namn, adress, telefonnummer samt e-post</li>
                      <li>Geografisk information</li>
                      <li>IP-adress, enhetsinformation, logginformation</li>
                      <li>Kundnummer</li>
                      <li>Uppgifter om dina inköp</li>
                    </ul>
                  </td>
                  <td>
                    Två år efter att du senast varit aktiv hos Hemset, till
                    exempel genom att handla hos oss eller kontakta vår
                    kundtjänst
                  </td>
                </tr>
                <tr>
                  <td>
                    För att försäkra oss om att lagkrav efterlevs, såsom
                    bokföringslagen
                  </td>
                  <td>Rättslig förpliktelse</td>
                  <td>
                    <ul>
                      <li>Namn, adress, telefonnummer samt e-post</li>
                      <li>Geografisk information</li>
                      <li>IP-adress, enhetsinformation, logginformation</li>
                    </ul>
                  </td>
                  <td>
                    Så länge vi är skyldiga att lagra uppgifterna enligt
                    tillämplig lag
                  </td>
                </tr>
                <tr>
                  <td>
                    För att marknadsföra Hemset eller koncernbolags varor och
                    tjänster genom post, e-post och telefon och för att utveckla
                    Hemsets tjänster.
                  </td>
                  <td>Intresseavvägning</td>
                  <td>
                    <ul>
                      <li>Namn, adress, telefonnummer samt e-post</li>
                      <li>Geografisk information</li>
                      <li>IP-adress, enhetsinformation, logginformation</li>
                    </ul>
                  </td>
                  <td>
                    Två år efter att du senast varit aktiv hos Hemset, till
                    exempel genom att handla hos oss eller kontakta vår
                    kundtjänst
                  </td>
                </tr>
              </tbody>
            </table>

            <h2>8. Profilering</h2>
            <p>
              8.1 Hemset kan behandla dina personuppgifter genom profilering om
              du är användare. Om du är användare analyseras information om hur
              du använder vår webb, om vilka av våra varor, tjänster och
              erbjudanden du varit intresserad av och vilka av våra nyhetsbrev
              du interagerat med, uppgifter om dina inköp för att tillhandahålla
              dig erbjudanden som vi tror passar dig. Detta gör vi med laglig
              grund intresseavvägning.
            </p>
            <p>
              8.2 Du kan närsomhelst invända mot behandling av personuppgifter
              genom profilering. Detta gör du genom att ringa Hemsets kundtjänst
              eller genom att kontakta info@hemset.se. När Hemset har mottagit
              din anmälan kommer Hemset att upphöra med att behandla dina
              personuppgifter för detta ändamål.
            </p>

            <h2>9. Hur länge sparar vi uppgifter om dig?</h2>
            <p>
              9.1 Dina personuppgifter sparas endast så länge som det finns ett
              behov av att spara dem för att fullgöra de ändamål som uppgifterna
              samlades in för i enlighet med denna Integritetspolicy.
            </p>
            <p>
              9.2 Hemset sparar uppgifter om användare i högst 24 månader efter
              det att användaren senast genomfört ett köp eller på annat sätt
              integrerat med Hemset.
            </p>
            <p>
              9.3 Hemset kan komma att spara uppgifterna längre om det behövs
              för att följa lagkrav eller för att bevaka Hemsets rättsliga
              intressen, t.ex. om det pågår en juridisk process.
            </p>

            <h2>10. Vem lämnar vi ut personuppgifter till?</h2>
            <p>
              10.1 Hemset kan komma att lämna ut dina uppgifter till tredje
              part, såsom till Hemsets koncernbolag och andra samarbetspartners
              som vi behöver för att leverera tjänsten till dig, samt
              leverantörer av kort- och kommunikationstjänster. Hemset kan även
              komma att lämna ut dina uppgifter till företag som tillhandahåller
              tjänster för adressuppdateringar för att säkerställa att vi har
              rätt adressuppgifter till dig.
            </p>
            <p>
              10.2 Tredje part som Hemset lämnar ut information till eller på
              annat sätt tillhandahåller information om en användare får endast
              använda informationen i syfte att sälja och marknadsföra Hemset
              och Hemsets tjänster och produkter eller i syfte att leverera
              tjänster med anknytning till Hemsets avtal med dig som användare.
            </p>
            <p>
              10.3 Personuppgifter kan vidare komma att lämnas ut av Hemset om
              det är nödvändigt för att följa gällande lagkrav eller krav från
              myndigheter, för att tillvarata Hemsets rättsliga intressen eller
              för att upptäcka, förebygga eller uppmärksamma bedrägerier och
              andra säkerhets- eller tekniska problem.
            </p>
            <p>
              10.4 Hemset kan komma att överföra dina personuppgifter till land
              utanför EU/EES, om någon av Hemsets leverantörer eller
              samarbetspartners befinner sig där. Om personuppgifter överförs
              till något land utanför EU/EES, kommer Hemset vidta åtgärder för
              att se till att personuppgifterna fortsätter vara skyddade och
              även vidta de åtgärder som krävs för att på lagligt sätt överföra
              personuppgifter till länder utanför EU/EES.
            </p>
            <p>
              10.5 Vi kommer inte att sälja dina personuppgifter till tredje
              part om vi inte har ditt tillstånd till det.
            </p>

            <h2>11. Ändring av integritetspolicy</h2>
            <p>
              11.1 Hemset har rätt att när som helst ändra Integritetspolicyn.
              Hemset kommer att med rimlig varsel underrätta dig vid ändringar
              av Integritetspolicyn. Om du inte godtar ändringarna har du rätt
              att säga upp avtalet med Hemset innan den ändrade
              Integritetspolicyn träder ikraft. Du säger upp avtalet genom att
              kontakta Hemset.
            </p>

            <h2>12. Skyddet av dina personuppgifter</h2>
            <p>
              Du ska alltid kunna känna dig trygg när du lämnar dina
              personuppgifter till oss. Hemset har därför vidtagit de
              säkerhetsåtgärder som behövs för att skydda dina personuppgifter
              mot otillbörlig åtkomst, förändring och radering. Exempelvis
              sparas alla uppgifter om användare i en databas som är skyddad
              genom behörighetsstyrning och brandvägg.
            </p>

            <h2>13. Dina rättigheter</h2>
            <p>
              13.1 Hemset ansvarar för att dina personuppgifter behandlas i
              enlighet med gällande lagstiftning.
            </p>
            <p>
              13.2 Hemset kommer på din begäran eller på eget initiativ att
              rätta, avidentifiera, radera eller komplettera uppgifter som
              upptäcks vara felaktiga, ofullständiga eller missvisande.
            </p>
            <p>13.3 Du har rätt att av begära</p>
            <ol>
              <li>
                Tillgång till dina personuppgifter. Det innebär att du har rätt
                att begära ett registerutdrag över den behandling som vi
                genomför avseende dina personuppgifter. Du har även rätt att få
                en kopia av de personuppgifter som behandlas. Du har rätt att
                kostnadsfritt erhålla ett registerutdrag från om vilka
                personuppgifter som finns registrerade om dig, ändamålen med
                behandlingen och till vilka mottagare uppgifterna har lämnats
                eller ska lämnas ut. Du har även rätt att i registerutdraget få
                information om var uppgifterna har hämtats ifrån om
                personuppgifterna inte har samlats in från dig, förekomsten av
                automatiserat beslutsfattande (inklusive profilering) samt den
                förutsedda period under vilken uppgifterna kommer att lagras
                eller kriterierna som används för att fastställa denna period.
                Du har vidare rätt att i registerutdraget få information om dina
                övriga rättigheter som anges i denna punkt. Vi kan komma att
                kräva ytterligare uppgifter från dig för att säkerhetsställa din
                identitet.
              </li>
              <li>
                Rättelse av dina personuppgifter. Vi kommer på din begäran att
                så snabbt som möjligt rätta de felaktiga eller ofullständiga
                uppgifter vi behandlar om dig.
              </li>
              <li>
                Radering av dina personuppgifter. Det innebär att du har rätt
                att begära att dina personuppgifter tas bort om de inte längre
                är nödvändiga för det ändamål de samlades in för. Det kan dock
                finnas lagkrav på att vi inte omedelbart får radera dina
                personuppgifter i exempelvis bokförings- och skattelagstiftning.
                Vi kommer då att avsluta den behandling som görs för andra
                ändamål än att följa lagstiftningen.
              </li>
              <li>
                Begränsning av behandling. Det innebär att dina personuppgifter
                markeras så att de endast får behandlas för vissa avgränsade
                ändamål. Du kan bland annat begära begränsning när du anser att
                dina uppgifter är felaktiga och du har begärt rättelse enligt
                punkt 12.3 b). Under tiden uppgifternas korrekthet utreds kommer
                behandlingen av dem att begränsas.
              </li>
            </ol>

            <p>
              13.4 Hemset kommer att underrätta varje mottagare till vilken
              personuppgifterna har lämnats ut enligt punkten 10 ovan om
              eventuella rättelser eller radering av uppgifter samt begränsning
              av behandling av uppgifter.
            </p>
            <p>
              13.5 Du har rätt till dataportabilitet. Det innebär en rätt att
              under vissa förutsättningar få ut och överföra dina
              personuppgifter i ett strukturerat, allmänt använt och
              maskinläsbart format. Om detta är tekniskt möjligt har ni även
              rätt till att de överförs till en annan personuppgiftsansvarig.
            </p>
            <p>
              13.6 Du har rätt att invända mot personuppgiftsbehandling som
              utförs med stöd av en intresseavvägning. Om du invänder mot sådan
              behandling kommer vi enbart att fortsätta behandlingen om det
              finns berättigade skäl till behandlingen som väger tyngre än dina
              intressen.
            </p>
            <p>
              13.7 Om du inte vill att behandlar dina personuppgifter för direkt
              marknadsföring har du alltid rätt att invända mot sådan behandling
              genom att skicka ett mail till info@hemset.se . När har vi
              mottagit din invändning kommer vi att upphöra med att behandla
              personuppgifterna för sådant marknadsföringsändamål.
            </p>
            <p>
              13.8 Du har rätt att inge eventuella klagomål angående behandling
              av dina personuppgifter till Datainspektionen.
            </p>

            <h2>14. Cookies</h2>
            <p>
              När du besöker vår hemsida använder vi cookies, du hittar mer
              information om hur vi behandlar cookies i vår{' '}
              <a href='https://www.hemset.se/conditions/cookiepolicy'>
                cookiepolicy
              </a>
              .
            </p>

            <h2>15. Kontaktinformation</h2>
            <p>
              15.1 Tveka inte att kontakta oss om du har några frågor om denna
              Integritetspolicy, behandlingen av dina personuppgifter eller om
              du vill begära ett registerutdrag. Vår kontaktinformation hittar
              du nedan.
            </p>

            <p>
              Hemset AB
              <br />
              Scheelevägen 27
              <br />
              223 70 Lund
              <br />
              info@hemset.se
            </p>
          </Flex>
        </Flex>
      </Flex>
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
